import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import HeaderFinancialPortal from '@components/FinancialPortal/Header/Header';
import { LayoutOnBoarding, SEO } from '@components/Structural';
import { makeStyles, Theme } from '@material-ui/core';
import { LayoutMobileInvestments } from '@components/Investments/Layout';
import { SLIDE_1, SLIDE_2, SLIDE_3 } from 'images/GCP';
import { useAuth } from '@hooks';
import { financialEndOptions } from '@interfaces';
import YouAreSet from '@components/ProductTour/YouAreSet/YouAreSet';
import CardSelectInterested from '@components/ProductTour/CardSelectInterested/CardSelectInterested';
import CardAssessment from '@components/ProductTour/CradAssessment/CardAssessment';

import { financialAnalysis } from '@utils';
import { getUserIdeaFinancialData } from '@apollo';

const FinancialPortal = () => {
  useAuth({ redirectIfNotLogged: true });
  const data = getUserIdeaFinancialData();

  console.log(data, 'data');
  const isMobile = useMediaQuery('(max-width: 1440px)');
  const [numberStep, setNumberStep] = useState(-1);
  const [userIdea, setUserIdea] = useState({
    averageIncome: 0,
    defaultDebts: false,
    fixedExpenses: 0,
    variableIncome: 0,
    savings: {
      thereSavings: true,
      savingsAccount: 0,
      mutualFunds: 0,
      termDeposit: 0,
      realEstate: 0,
    },
    debts: {
      thereCredits: true,
      consumer: 0,
      automotive: 0,
      realEstate: 0,
      totalLinesOfCredit: 0,
    },
    variableExpenses: 0,
  });
  const [endOptions, setEndOptions] = useState<financialEndOptions>({
    status: 'Saludable',
    defaultDebt: false,
    preferences: [0],
  });
  const [isSelect, setSelect] = useState(0);
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [isTransactions, setTransactions] = useState<boolean>(false);

  let title;
  let subtitleBold;
  let subtitle;
  let image;
  let color;

  switch (numberStep) {
    case 0:
      title = listSteps[0].title;
      subtitleBold = listSteps[0].subtitleBold;
      subtitle = listSteps[0].subtitle;
      image = listSteps[0].image;
      color = listSteps[0].color;

      break;
    case 1:
      title = listSteps[1].title;
      subtitleBold = listSteps[1].subtitleBold;
      subtitle = listSteps[1].subtitle;
      image = listSteps[1].image;
      color = listSteps[1].color;

      break;
    case 2:
      title = listSteps[2].title;
      subtitleBold = listSteps[2].subtitleBold;
      subtitle = listSteps[2].subtitle;
      image = listSteps[2].image;
      color = listSteps[2].color;

      break;

    case 5:
      color = listSteps[5].color;
      break;

    default:
      color = listSteps[0].color;

      break;
  }
  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };
  const classes = useStyles();
  const [isState, setState] = useState(0);

  const dataSave = data.data?.userIdeaFinancialData;

  const [analysisScore, setAnalysisScore] = useState(
    financialAnalysis(
      dataSave?.averageIncome || 0,
      dataSave?.fixedExpenses || 0,
      dataSave?.variableExpenses || 0,
      dataSave?.debts?.thereCredits ? dataSave?.debts?.consumer || 0 : 0,
      dataSave?.debts?.thereCredits ? dataSave?.debts?.automotive || 0 : 0,
      dataSave?.debts?.thereCredits ? dataSave?.debts?.realEstate || 0 : 0,
      dataSave?.debts?.thereCredits
        ? dataSave?.debts?.totalLinesOfCredit || 0
        : 0,
      dataSave?.savings?.thereSavings
        ? dataSave?.savings?.savingsAccount || 0
        : 0,
      dataSave?.savings?.thereSavings ? dataSave?.savings?.mutualFunds || 0 : 0,
      dataSave?.savings?.thereSavings ? dataSave?.savings?.termDeposit || 0 : 0,
      dataSave?.averageIncome || 0,
      dataSave?.variableIncome || 0,
      23
    )
  );

  useEffect(() => {
    setAnalysisScore(
      financialAnalysis(
        dataSave?.averageIncome || 0,
        dataSave?.fixedExpenses || 0,
        dataSave?.variableExpenses || 0,
        dataSave?.debts?.thereCredits ? dataSave?.debts?.consumer || 0 : 0,
        dataSave?.debts?.thereCredits ? dataSave?.debts?.automotive || 0 : 0,
        dataSave?.debts?.thereCredits ? dataSave?.debts?.realEstate || 0 : 0,
        dataSave?.debts?.thereCredits
          ? dataSave?.debts?.totalLinesOfCredit || 0
          : 0,
        dataSave?.savings?.thereSavings
          ? dataSave?.savings?.savingsAccount || 0
          : 0,
        dataSave?.savings?.thereSavings
          ? dataSave?.savings?.mutualFunds || 0
          : 0,
        dataSave?.savings?.thereSavings
          ? dataSave?.savings?.termDeposit || 0
          : 0,
        dataSave?.averageIncome || 0,
        dataSave?.variableIncome || 0,
        23
      )
    );
  }, [dataSave, setAnalysisScore, isSelect]);

  return (
    <>
      <SEO
        title="Administra tus finanzas personales - Rokin"
        description="Organiza tu plata, controla tus gastos, maneja tus deudas y potencia tu ahorro todo desde un solo lugar."
      />
      {!isMobile ? (
        <LayoutOnBoarding activeFinance>
          <HeaderFinancialPortal
            background={!isMobile && isSelect !== 1 ? '' : '#00323c'}
            className={`${isTransactions ? classes.backgroundColor : ''}`}
            setSelect={setSelect}
            isSelect={isSelect}
            infoPopup={infoButtonHandler}
            which={'myFinances'}
          >
            {isSelect === 0 && (
              <CardSelectInterested
                onContinueClick={() => setState(0)}
                userIdea={userIdea}
                setEndOptions={setEndOptions}
                analysisScore={analysisScore}
                setSelectMenu={setSelect}
              />
            )}
            {isSelect === 1 && (
              <>
                {isState === 0 && (
                  <>
                    <CardAssessment
                      setState={setState}
                      setSelectMenu={setSelect}
                      step={2}
                    />
                  </>
                )}
              </>
            )}
          </HeaderFinancialPortal>
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeFinance>
          <HeaderFinancialPortal
            background={!isMobile && isSelect !== 1 ? '' : '#00323c'}
            className={`${isTransactions ? classes.backgroundColor : ''}`}
            setSelect={setSelect}
            isSelect={isSelect}
            infoPopup={infoButtonHandler}
            which={'myFinances'}
          >
            {isSelect === 0 && (
              <CardSelectInterested
                onContinueClick={() => setState(0)}
                userIdea={userIdea}
                setEndOptions={setEndOptions}
                analysisScore={analysisScore}
              />
            )}
            {isSelect === 1 && (
              <>
                {isState === 0 && (
                  <>
                    <CardAssessment
                      setState={setState}
                      step={2}
                      setSelectMenu={setSelect}
                    />
                  </>
                )}
              </>
            )}
          </HeaderFinancialPortal>
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default FinancialPortal;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {},
  myAccountsContainer: {
    [theme.breakpoints.down(1240)]: {
      marginTop: '1rem',
      paddingTop: '1rem',
      background: '#008195',
    },
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    // height: "150%",
    zIndex: 1000,
    margin: 0,
    backgroundColor: '#F4F4F3',
    top: 0,
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  backgroundColor: {
    background: '#00323c',
  },
  containerInput: {
    marginBottom: '2rem',
    background: 'red',
  },
  bottom: {
    paddingBottom: '7rem',
    color: 'white',
    display: 'flex',
    margin: '0 auto',
  },
  bottom2: {
    color: 'white',
    display: 'flex',
    margin: '0 auto',
    [theme.breakpoints.down(900)]: {
      margin: '0 auto 4.5rem',
    },
  },
  popUpContainer: {
    zIndex: 1000,
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100rem',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10%',
    [theme.breakpoints.down(1240)]: {
      paddingTop: '35%',
    },
  },
  outerBorder: {
    width: '43.938rem',
    height: '21rem',
    borderRadius: '40px',
    border: '1px solid #FFFFFF',
    padding: '0.25rem',
    [theme.breakpoints.down(1240)]: {
      width: '21.063rem',
      height: '30.188rem',
    },
  },
  popUp: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '35px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    [theme.breakpoints.down(1240)]: {
      borderRadius: '35px',
    },
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  popUpVector: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    margin: '0.7rem 0 0 0',
    [theme.breakpoints.down(1240)]: {},
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.43',
    color: '#393E44',
    [theme.breakpoints.down(1240)]: {
      marginTop: '1rem',
    },
  },
  subtext: {
    fontFamily: 'Noto Sans, Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '1.67',
    color: '#393E44',
    margin: '1.2rem 0 0 0',
  },
  buttonGotItContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1240)]: {
      margin: '1.5rem 0 0 0',
    },
  },
  buttonGotIt: {
    margin: '1.2rem 0 0 0',
    width: '3rem',
  },
  textButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  rokipediaPopUpTitle: {
    fontFamily: 'Nunito, Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#393E44',
    margin: '0 70% 0 0 ',
    [theme.breakpoints.down(1240)]: {
      margin: '0 30% 0 3% ',
    },
  },
  image: {
    margin: '1.5rem 1rem 0 0',
    [theme.breakpoints.down(1240)]: {
      margin: '0.7rem 1rem 0 0',
    },
  },
  image2: {
    margin: '0.7rem 1rem 0 0',
    [theme.breakpoints.down(1240)]: {
      margin: '-1.6rem 1rem 0 0',
    },
  },
  strongText: {
    fontWeight: 'bolder',
    color: 'black',
  },
  hiddenDisplay: {
    display: 'none',
  },
}));

const listSteps = [
  {
    id: 0,
    title: '¿Tienes algún convenio para activar? (empresa u otro)',
    subtitleBold: '',
    subtitle:
      'Confirmaremos que pertenezcas al convenio para activar tus beneficios.',
    image: SLIDE_1,
    color: '#008296',
  },
  {
    id: 1,
    title: (
      <>
        Para que no vuelvas <br />a pagar de más
      </>
    ),
    subtitleBold: '',
    subtitle:
      'Toma el control de tus gastos, potencia tu ahorro y descubre las mejores alternativas y descuentos en diferentes servicios.',
    image: SLIDE_2,
    color: '#8352fd',
  },
  {
    id: 2,
    title: 'Hecho a tu medida',
    subtitleBold: 'Tu camino es único. ',
    subtitle:
      'Encuentra herramientas y un acompañamiento personalizado para lo que tu necesitas.',
    image: SLIDE_3,
    color: '#008296',
  },
  {
    id: 3,
    color: '#008296',
  },
  { id: 4 },
  { id: 5 },
  { id: 6, color: '#084554' },
];
